import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import LoadScript from "vue-plugin-load-script";
import Toasted from "vue-toasted";
import VModal from "vue-js-modal";

Vue.use(LoadScript);
Vue.use(VModal);
Vue.use(Toasted, { iconPack: 'fontawesome' });
Vue.loadScript("/theme/js/jquery.min.js")
  .then(() => {
    Vue.loadScript("/theme/js/bootstrap.bundle.min.js");
    Vue.loadScript("/theme/js/default/classy-nav.min.js");
    Vue.loadScript("/theme/js/waypoints.min.js");
    Vue.loadScript("/theme/js/jquery.easing.min.js");
    Vue.loadScript("/theme/js/default/jquery.scrollup.min.js");
    Vue.loadScript("/theme/js/owl.carousel.min.js");
    Vue.loadScript("/theme/js/wow.min.js");
    Vue.loadScript("/theme/js/jarallax.min.js");
    Vue.loadScript("/theme/js/jarallax-video.min.js");
    Vue.loadScript("/theme/js/default/cookiealert.js");
    Vue.loadScript("/theme/js/default/jquery.passwordstrength.js");
    Vue.loadScript("/theme/js/jquery.countdown.min.js");
    Vue.loadScript("/theme/js/default/active.js");
  })
  .catch(() => { });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

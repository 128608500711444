<template>
  <div>
    <section class="about-area about-jc section-padding-120 bg-gray">
      <div class="container">
        <div class="hero--content--area">
          <div class="row justify-content-center g-4">
            <div class="col-12 col-md-3">
              <div class="red-border">
                <div class="text-center">{{ user.phone }}</div>
                <ul>
                  <li @click="select('cargo')">Миний тээврүүд</li>
                  <li @click="select('news')">Мэдээ</li>
                  <li @click="select('profile')">Профайл</li>
                  <li @click="logout">Гарах</li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-9">
              <div class="red-border">
                <!-- Миний тээвэрүүд -->
                <div class="tab" id="cargo">
                  <div>Миний тээврүүд</div>
                  <div class="inner">
                    <div class="row">
                      <div class="col-12 col-md-4 py-1 py-md-0">
                        <select
                          class="form-control rounded-1 wow fadeInUp"
                          placeholder="Төлөв"
                          name=""
                          id="cargoStatus"
                          v-model="cargoStatus"
                        >
                          <option
                            v-for="(status, idx) in cargoStatuses"
                            :key="idx"
                            :value="status"
                          >
                            {{ status === "" ? "Бүх төлөв" : status }}
                          </option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4 offset-md-4 py-1 py-md-0">
                        <input
                          type="text"
                          class="form-control rounded-1 wow fadeInUp"
                          placeholder="Хайх"
                          name=""
                          id="cargoSearch"
                          v-model="cargoSearch"
                        />
                      </div>
                    </div>
                    <div
                      v-for="track in filteredTracks"
                      :key="track.id"
                      class="mobile"
                      style="
                        border: 1px solid white;
                        padding: 16px;
                        margin-top: 16px;
                        border-radius: 16px;
                      "
                    >
                      <div class="d-flex justify-content-between mt-2">
                        <div>Б᠌/Д</div>
                        <div class="text-right">{{ track.track_number }}</div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>Трак №</div>
                        <div class="text-right">
                          {{ track.track.track_code }}
                        </div>
                      </div>

                      <div class="d-flex justify-content-between mt-2">
                        <div>Салбар</div>
                        <div class="text-right">
                          {{ track.track.branch ? track.track.branch.name : "" }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>Хүргэлтээр авах</div>
                        <div class="text-right">
                          {{ track.track.pick_up ? "Тийм" : "Үгүй" }}
                        </div>
                      </div>

                      <div class="d-flex justify-content-between mt-2">
                        <div>Хэмжээ /см/</div>
                        <div class="text-right">
                          {{
                            track.track.length
                              ? track.track.length +
                                "*" +
                                track.track.width +
                                "*" +
                                track.track.height
                              : ""
                          }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>Жин /кг/</div>
                        <div class="text-right">{{ track.track.kilogram }}</div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>Төлбөр ₮</div>
                        <div class="text-right">
                          {{
                            track.track.amount
                              ? Math.round(track.track.yuan * track.track.amount)
                              : ""
                          }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>Тавцан</div>
                        <div class="text-right">
                          {{ track.track.rack ? track.track.rack.name : "" }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>Төлөв</div>
                        <div class="text-right">{{ track.status.status }}</div>
                      </div>
                      <div class="d-flex justify-content-between mt-2">
                        <div>Огноо</div>
                        <div class="text-right">{{ track.created }}</div>
                      </div>
                    </div>
                    <table
                      class="desktop table text-white mt-2 wow fadeInUp table-responsive"
                    >
                      <thead>
                        <tr>
                          <th>Бүртгэлийн дугаар</th>
                          <th>Трак №</th>
                          <th>Салбар</th>
                          <th>Х/А</th>
                          <th>Хэмжээ /см/</th>
                          <th>Жин /кг/</th>
                          <th>Төлбөр ₮</th>
                          <th>Тавцан</th>
                          <th>Төлөв</th>
                          <th class="text-right">Огноо</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="track in filteredTracks" :key="track.id">
                          <td>{{ track.track_number }}</td>
                          <td style="max-width: 150px; word-wrap: break-word">
                            {{ track.track.track_code }}
                          </td>
                          <td>
                            {{ track.track.branch ? track.track.branch.name : "" }}
                          </td>
                          <td>
                            <label class="checkbox-container text-white">
                              <input
                                type="checkbox"
                                disabled
                                :checked="track.track.pick_up"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            {{
                              track.track.length
                                ? track.track.length +
                                  "*" +
                                  track.track.width +
                                  "*" +
                                  track.track.height
                                : ""
                            }}
                          </td>
                          <td>{{ track.track.kilogram }}</td>
                          <td>
                            {{
                              track.track.amount
                                ? Math.round(track.track.yuan * track.track.amount)
                                : ""
                            }}
                          </td>
                          <td>
                            {{ track.track.rack ? track.track.rack.name : "" }}
                          </td>
                          <td>
                            <div v-if="track.track.amount">
                              {{ track.status.status }}
                            </div>
                            <div v-else>Бүртгэгдсэн</div>
                          </td>
                          <td class="text-right">{{ track.created }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Мэдээ -->
                <div class="tab" id="news">
                  <div>Мэдээ</div>
                  <div class="inner">
                    <div class="row">
                      <div class="col-12 col-md-4 py-1 py-md-0">
                        <select
                          class="form-control rounded-1 wow fadeInUp"
                          placeholder="Төлөв"
                          name=""
                          id="newsStatus"
                          v-model="newsStatus"
                        >
                          <option
                            v-for="(status, idx) in cargoStatuses"
                            :key="idx"
                            :value="status"
                          >
                            {{ status === "" ? "Бүх төлөв" : status }}
                          </option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4 offset-md-4 py-1 py-md-0">
                        <input
                          type="text"
                          class="form-control rounded-1 wow fadeInUp"
                          placeholder="Хайх"
                          name=""
                          id="newsSearch"
                          v-model="newsSearch"
                        />
                      </div>
                    </div>
                    <table class="table text-white mt-2 wow fadeInUp">
                      <thead>
                        <tr>
                          <th>Огноо</th>
                          <th>Төлөв</th>
                          <th>Мэдээ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(news, idx) in filteredNews" :key="idx">
                          <td style="min-width: 90px">{{ news.created }}</td>
                          <td>
                            {{ news.data.status ? news.data.status : "" }}
                          </td>
                          <td>{{ news.data.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Профайл -->
                <div class="tab" id="profile">
                  <div>Профайл</div>
                  <div class="inner">
                    <form @submit.prevent="handleUpdate">
                      <div class="row py-1">
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <input
                            type="text"
                            class="form-control rounded-1 wow fadeInUp"
                            placeholder="Овог"
                            name=""
                            id="lastname"
                            v-model="user.lastname"
                          />
                        </div>
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <input
                            type="text"
                            class="form-control rounded-1 wow fadeInUp"
                            placeholder="Нэр"
                            name=""
                            id="firstname"
                            v-model="user.firstname"
                          />
                        </div>
                      </div>
                      <!-- <div class="row py-1">
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <select
                            class="form-control rounded-1 wow fadeInUp"
                            v-model="branch.id"
                          >
                            <option
                              v-for="branch in branches"
                              :value="branch.id"
                              :key="branch.id"
                            >
                              {{ branch.name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <input
                            class="custom-checkbox"
                            type="checkbox"
                            id="pickUp"
                            name="pickUp"
                            :value="branch.pickUp"
                          />
                          <label for="pickUp" class="custom-checkbox-label"
                            >Хүргэлтээр авах</label
                          >
                        </div>
                      </div> -->
                      <div class="row py-1">
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <input
                            type="text"
                            class="form-control rounded-1 wow fadeInUp"
                            placeholder="Утасны дугаар"
                            name=""
                            id="phone"
                            v-model="user.phone"
                            readonly
                          />
                        </div>
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <button class="btn btn-red w-100 wow fadeInUp">
                            Засварлах
                          </button>
                        </div>
                      </div>
                    </form>
                    <hr />
                    <form @submit.prevent="handlePassword">
                      <div class="row py-1">
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <input
                            type="text"
                            class="form-control rounded-1 wow fadeInUp"
                            placeholder="Одоогийн нууц үг"
                            name=""
                            id="current"
                            v-model="passArr.current"
                          />
                        </div>
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <input
                            type="text"
                            class="form-control rounded-1 wow fadeInUp"
                            placeholder="Шинэ нууц үг"
                            name=""
                            id="new"
                            v-model="passArr.new"
                          />
                        </div>
                      </div>
                      <div class="row py-1">
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <input
                            type="text"
                            class="form-control rounded-1 wow fadeInUp"
                            placeholder="Баталгаажуулах нууц үг"
                            name=""
                            id="confirm"
                            v-model="passArr.confirm"
                          />
                        </div>
                        <div class="col-12 col-md-6 py-1 py-md-0">
                          <button class="btn btn-red w-100 wow fadeInUp">
                            Засварлах
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import User from "../models/user";
import $ from "jquery";
import cargoService from "../services/cargo.service";

export default {
  name: "Profile",
  data() {
    return {
      user: new User(),
      tracks: [],
      allNews: [],
      selected: "cargo",
      cargoStatuses: ["", "Бүртгэгдсэн", "Ирсэн", "Хүлээн авсан", "Цуцлагдсан"],
      cargoSearch: "",
      cargoStatus: "",

      newsSearch: "",
      newsStatus: "",

      trackLink: null,
      trackMeta: null,
      passArr: {
        current: null,
        new: null,
        confirm: null,
      },
      branch: {
        id: null,
        pickUp: true,
      },

      branches: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    filteredTracks: function () {
      let search = this.cargoSearch.toLowerCase();
      let status = this.cargoStatus;
      return this.tracks.filter(
        (data) =>
          (data.track_number.toLowerCase().includes(search) ||
            data.track_code.toLowerCase().includes(search) ||
            data.created.toLowerCase().includes(search)) &&
          data.status.status.includes(status)
      );
    },

    filteredNews: function () {
      let search = this.newsSearch.toLowerCase();
      let status = this.newsStatus;
      // console.log("STATUS:" + status);
      return this.allNews.filter(
        (news) =>
          (news.data.message.toLowerCase().includes(search) ||
            news.created.toLowerCase().includes(search)) &&
          (news.data.status ? news.data.status.includes(status) : true)
      );
    },
  },
  mounted() {
    $("#" + this.selected).show();
  },
  created() {
    cargoService
      .getBranches()
      .then((res) => {
        this.branches.push({
          id: null,
          name: "Бараа хүлээн авах салбар сонгоно уу",
          address: null,
          phone: null,
        });
        this.branches = this.branches.concat(res.data.body);
      })
      .catch((err) => {
        console.log(err.response.message);
      });
    if (!this.loggedIn) {
      this.$router.push("/login");
    } else {
      UserService.getUserProfile()
        .then((response) => {
          let body = response.data.body;
          this.user = new User(body.user.lastname, body.user.firstname, body.user.phone);
        })
        .catch((error) => console.log(error.response.data));

      UserService.getTracks().then((response) => {
        this.tracks = response.data.data;
        this.trackLink = response.data.links;
        this.trackMeta = response.data.meta;
      });

      UserService.getNews().then((response) => {
        this.allNews = response.data.body;
      });
    }
  },
  methods: {
    select(tab) {
      $("#" + tab)
        .siblings()
        .hide();
      $("#" + tab).show();
    },
    handleUpdate() {
      UserService.updateProfile(this.user)
        .then((response) => {
          this.user.lastname = response.data.body.lastname;
          this.user.firstname = response.data.body.firstname;
          this.$toasted.show("Мэдээлэл амжилттай шинэчлэгдлээ", {
            type: "success",
            duration: 4000,
            keepOnHover: true,
            icon: "exclamation-circle",
            position: "bottom-right",
          });
        })
        .catch((error) => {
          this.$toasted.show(error.message, {
            type: "error",
            duration: 4000,
            keepOnHover: true,
            icon: "exclamation-circle",
            position: "bottom-right",
          });
        });
    },
    handlePassword() {
      if (this.passArr.new === this.passArr.confirm) {
        UserService.changePassword(this.passArr)
          .then((response) => {
            let message = response.data.messages;
            let type = response.data.success ? "success" : "error";

            this.$toasted.show(message, {
              type: type,
              duration: 4000,
              keepOnHover: true,
              icon: "exclamation-circle",
              position: "bottom-right",
            });
            if (response.data.success) this.passArr = {};
          })
          .catch((error) => {
            this.$toasted.show(error.response.data, {
              type: "error",
              duration: 4000,
              keepOnHover: true,
              icon: "exclamation-circle",
              position: "bottom-right",
            });
          });
      } else {
        this.$toasted.show("Шинэ болон Баталгаажуулах нууц үг тохирохгүй байна.", {
          type: "error",
          duration: 4000,
          keepOnHover: true,
          icon: "exclamation-circle",
          position: "bottom-right",
        });
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
  },
};
</script>

class Api {
    constructor() {
        this.baseUrl = 'https://www.jinchin.mn/';
        // this.baseUrl = 'http://jinchin.test/';
        this.url = this.baseUrl + 'api/v1/user/';
        this.storageUrl = this.baseUrl + 'storage/';

    }
}
export default new Api

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Calculation from "../views/Calculation.vue";
import Contact from "../views/Contact.vue";
import Help from "../views/Help.vue";
import Profile from "../views/Profile.vue";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import HelpDetail from "../views/HelpDetail.vue";
import NotFound from "../views/NotFound.vue";
import Terms from "../views/Terms.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/training",
    name: "Training",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Training.vue")
  },
  {
    path: "/calculation",
    name: "Calculation",
    component: Calculation
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/help",
    name: "Help",
    component: Help
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/help-detail/:id",
    name: "HelpDetail",
    component: HelpDetail
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

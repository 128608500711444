<template>
  <div>
    <section class="welcome-area-black demo-hero" id="home">
      <!-- Background Shape-->
      <div class="background-shape">
        <div
          class="circle1 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle2 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle3 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle4 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
      </div>
      <!-- Background Animation-->
      <div class="background-animation">
        <div class="star-ani"></div>
        <div class="cloud-ani"></div>
        <div class="circle-ani"></div>
        <div class="box-ani"></div>
      </div>
      <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
          <!-- Welcome Content-->
          <div class="col-12 col-md-4">
            <div class="welcome-content">
              <!-- <h6 class="version-number mb-3">Now v1.6</h6> -->
              <div class="card register-card bg-gray p-1 p-sm-4 mb-50">
                <div class="card-body">
                  <h4>Нэвтрэх</h4>
                  <p style="color: #191919; font-size: 12px; margin-bottom: 0">
                    Манай ФБ чатаар болон утсаар холбогдож нууц үг сэргээнэ үү.
                  </p>
                  <!-- Register Form-->
                  <div class="register-form my-5">
                    <form @submit.prevent="handleLogin">
                      <div class="form-group mb-3">
                        <input
                          class="form-control rounded-0"
                          type="text"
                          placeholder="Утасны дугаар"
                          required=""
                          v-model="user.phone"
                        />
                      </div>
                      <div class="form-group mb-3">
                        <label
                          class="label-psswd"
                          @click="showPassword"
                          for="password"
                          ><span class="hide">{{ btnText }}</span
                          ><span class="show">{{ btnText }}</span></label
                        >
                        <input
                          class="input-psswd form-control rounded-0"
                          id="password"
                          :type="type"
                          placeholder="Нууц үг"
                          psswd-shown="true"
                          required=""
                          v-model="user.password"
                        />
                        <div
                          class="password-meter-wrapper progress"
                          style="display: none"
                        >
                          <div
                            id="password-progress"
                            class="progress-bar"
                            role="progressbar"
                            aria-valuenow="1"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style="width: 1%"
                          ></div>
                        </div>
                        <div
                          id="password-score"
                          class="password-score"
                          style="display: none"
                        ></div>
                        <div
                          id="password-recommendation"
                          class="password-recommendation"
                        ></div>
                        <input
                          type="hidden"
                          id="password-strength-score"
                          value="0"
                        />
                      </div>
                      <div
                        class="text-center text-red py-2 wow fadeIn small"
                        v-if="errors.length"
                      >
                        {{ errors.join("\n") }}
                      </div>
                      <button class="btn btn-red w-100" type="submit">
                        <i class="lni-unlock mr-2"></i>Нэвтрэх
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import User from "../models/user";

export default {
  name: "Login",
  data() {
    return {
      user: new User(),
      loading: false,
      message: "",
      errors: [],
      type: "password",
      btnText: "SHOW",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "HIDE";
      } else {
        this.type = "password";
        this.btnText = "SHOW";
      }
    },
    handleLogin() {
      this.loading = true;
      this.errors = [];
      if (this.user.phone && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/profile");
          },
          (error) => {
            this.loading = false;
            this.$toasted.show(error.response.data.messages, {
              type: "error",
              duration: 4000,
              keepOnHover: true,
              icon: "exclamation-circle",
              position: "bottom-right",
            });
            this.errors = error.response.data.messages;
            // this.message =
            //   (error.response && error.response.data) ||
            //   error.message ||
            //   error.toString();
          }
        );
      }
    },
  },
};
</script>

import axios from 'axios';
import api from '../models/api';

class AuthService {
    login(user) {
        return axios
            .post(api.url + 'login', {
                phone: user.phone,
                password: user.password,
                domain: 'jcargo'
            }, {"X-Requested-With": "XMLHttpRequest"})
            .then(response => {
                if (response.data.body.token) {
                    localStorage.setItem('token', JSON.stringify(response.data.body.token));
                }
                return response.data.body.token;
            });
    }

    logout() {
        localStorage.removeItem('token');
    }

    register(user) {
        return axios.post(api.url + 'register', {
            lastname: user.lastname,
            firstname: user.firstname,
            phone: user.phone,
            password: user.password,
            domain: 'jcargo'
        }, {"X-Requested-With": "XMLHttpRequest"})
            .then(response => {
            if (response.data.body.token) {
                localStorage.setItem('token', JSON.stringify(response.data.body.token));
            }
            return response.data.body.token;
        });
    }
}

export default new AuthService();
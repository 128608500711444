<template>
  <div>
    <div class="saasbox-contact-us-area section-padding-120-40">
      <div class="container">
        <div class="row justify-content-between">
          <!-- Contact Side Info-->
          <div class="col-12 col-lg-5 col-xl-4">
            <div class="contact-side-info mb-80">
              <h1 class="mb-3 text-red">Холбоо барих</h1>
              <p class="mb-4">Jcargo тээвэр зууч</p>
              <div class="contact-mini-card-wrapper">
                <!-- Contact Mini Card-->
                <div class="contact-mini-card">
                  <div class="contact-mini-card-icon">
                    <i class="lni lni-facebook-original"></i>
                  </div>
                  <a href="https://www.facebook.com/Jcargo.mn/">
                    <p>Facebook</p>
                  </a>
                </div>
                <!-- Contact Mini Card-->
                <div class="contact-mini-card">
                  <div class="contact-mini-card-icon">
                    <i class="lni lni-phone"></i>
                  </div>
                  <p>(+976) 7600-0006</p>
                </div>
                <!-- Contact Mini Card-->
                <div class="contact-mini-card">
                  <div class="contact-mini-card-icon">
                    <i class="lni lni-timer"></i>
                  </div>
                  <p>Ажилын өдрүүдэд 09:00 - 19:00.</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Contact Form-->
          <div class="col-12 col-lg-7">
            <div class="google-maps-wrapper">
              <iframe
                src="https://maps.google.com/maps?q=Хос+Жинчин+Экспресс+ХХК&z=15&output=embed"
                allowfullscreen=""
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
export default {
  // components: {
  //   HelloWorld
  // }
};
</script>

<template>
  <div class="container">
    <section class="about-area section-padding-120">
      <h5 class="text-center text-red font-italic">ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</h5>
      <p>
        <b>Нэг. Ерөнхий заалт</b> <br />
        1.1. www.jcargo.mn нь Эрээн-Улаанбаатар карго тээвэр үйлчилгээ үзүүлэх
        албан ёсны вэбсайт бөгөөд энэхүү нөхцөл, журам нь уг вэбсайтаар
        үйлчлүүлэхтэй холбоотой үүссэн харилцааг зохицуулахад оршино. <br />
        1.2. Үйлчлүүлэгч үйлчилгээ авахаасаа өмнө энэхүү журамтай сайтар
        танилцах ба журамд тусгагдсан нөхцөлүүдийг бүрэн хүлээн зөвшөөрсөн
        тохиолдолд үйлчилгээ авна уу. <br />
        1.3. Үйлчлүүлэгч вэбсайтын талаар гомдол, санал, хүсэлт, шүүмж зэргийг
        вэбийн журмын дагуу чөлөөтэй илэрхийлэх, илгээх эрхтэй. <br />
        <b>Хоёр. Вебсайтын хэрэглэгч ба гишүүнчлэл</b> <br />
        2.1.Вэбсайтын хэрэглэгч нь гишүүнчлэлтэй байна. Монгол улсын ба гадаад
        улсын 18 нас хүрсэн эрх зүйн чадамжтай иргэн бүр гишүүн болох боломжтой.
        <br />
        2.2. Гишүүний хувь хүний мэдээллийн бүрэн бүтэн хангах болон үнэн зөв
        байдлыг байгууллага шалгах эрхтэй. Хэрэглэгчийн аливаа мэдээлэл нь хувь
        хүний нууцад хамаарах ба нууцлалын байдлыг байгууллага бүрэн хариуцна.
        <br />
        2.3. Хэрэглэгч өөрийн гаргаж өгсөн мэдээллийн үнэн зөв, бодит байдлыг
        бүрэн хариуцна. Хэрэглэгч санаатайгаар буруу ташаа мэдээлэл өгөх эсвэл
        санамсаргүйгээр дутуу мэдээлэл өгсөн тохиолдолд үүнээс болж үүдэн гарах
        бүх хариуцлагыг хэрэглэгч өөрөө хүлээнэ. <br />
        2.4. Нэг хэрэглэгч зөвхөн нэг Монгол утасны дугаараар гишүүн болох
        эрхтэй. Бүртгүүлсэн утасны дугаараар дахин бүртгүүлэх боломжгүй. <br />
        2.5. Шинээр бүртгүүлж вэбийн гишүүн болмогц тухайн хэрэглэгчид данс
        үүснэ. Дансанд тухайн хэрэглэгчийн үйлчилгээний түүх, өөрийн мэдээллүүд
        багтана. <br />
        <b>Гурав. Тээвэрлэлтийн үйлчилгээ</b> <br />
        3.1. Үйлчлүүлэгч Эрээн-Улаанбаатарын чиглэлд бараа тээвэрлүүлж байгаа
        тохиолдолд тээврийн хөлсийг бараагаа хүлээж авахдаа төлнө. Байгууллага
        Үйлчлүүлэгчийн тээвэрлүүлсэн барааны жин, овор хэмжээг харгалзан үзэж
        тээврийн хөлсийг бодож авна. Үйлчлүүлэгч өөрийн захиалсан барааныхаа
        тээвэр зуучийн компаний нэр болон трак кодыг алдаатай оруулснаас үүдэн
        гарах аливаа хариуцлагыг байгууллага хариуцахгүй. <br />
        3.2. Үйлчлүүлэгчийн захиалсан бараа манай компаний Эрээн дэх агуулахад
        ирсэн өдрөөс хойш 2-3 хоногт Улаанбаатар хотод ирнэ. Бүх нийтийн баяр
        ёслол,гэнэтийн давагдашгүй хүчин үйл үүссэн тохиолдолд тодорхой болон
        тодорхойгүй хугацаагаар сунгагдаж болно. Гэнэтийн болон давагдашгүй
        хүчин зүйл гэдэгт гал түймэр, газар хөдлөлт, салхи шуурга, аянга
        цахилгаан, үер усны гамшиг, нийтийг хамарсан үймээн самуун, эмх
        замбараагүй байдал, ажил хаялт, эрчим хүчний тасалдал, эсэргүүцлийн
        хөдөлгөөн, гоц халдварт өвчин, дайн, террорист халдлага, төрийн эрх
        бүхий байгууллагын шийдвэр, хорио, цээр зэрэг талуудын хүсэл зоригоос үл
        хамаарах шалтгаанууд багтана. <br />
        <b>Дөрөв. Бусад заалт</b> <br />
        4.1. Үйлчлүүлэгч үйлчилгээтэй холбоотой аливаа мэдээлэл, санал хүсэлт,
        гомдол зэргийг веб сайтын чатаар өгнө. 4.2. Үйлчлүүлэгчийн гаргасан
        гомдлыг байгууллага сэтгэлд нь нийцэх хүртэл шийдвэрлэхийг эрмэлзэнэ.
        Үйлчилгээтэй холбоотой аливаа маргаантай асуудлыг вебсайтын үйлчилгээний
        нөхцөл болон Монгол Улсын иргэний хуулийн дагуу шийдвэрлэнэ. <br />
        <span class="text-red">БАРАА ОЛГОХ ЦЭГИЙН ХАЯГ БАЙРШИЛ:</span> БЗД
        14хороо, Халдвартын эцсийн буудлын зүүн тал 3 давхар шилэн объект
        JINCHIN.MN BUILDING 1 давхарт <br />
        <!--        <span class="text-red">ОРЦНЫ КОД:</span> 1520 <br />-->
        <span class="text-red">FACEBOOK:</span>
        <a class="text-white" href="https://www.facebook.com/Jcargo.mn">
          https://www.facebook.com/Jcargo.mn</a
        >
      </p>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <div>
    <section class="welcome-area-black demo-hero" id="home">
      <!-- Background Shape-->
      <div class="background-shape">
        <div class="circle1 wow fadeInRightBig" data-wow-duration="4000ms"></div>
        <div class="circle2 wow fadeInRightBig" data-wow-duration="4000ms"></div>
        <div class="circle3 wow fadeInRightBig" data-wow-duration="4000ms"></div>
        <div class="circle4 wow fadeInRightBig" data-wow-duration="4000ms"></div>
      </div>
      <!-- Background Animation-->
      <div class="background-animation">
        <div class="star-ani"></div>
        <div class="cloud-ani"></div>
        <div class="circle-ani"></div>
        <div class="box-ani"></div>
      </div>
      <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
          <!-- Welcome Content-->
          <div class="col-12 col-md-8">
            <div class="welcome-content text-center">
              <!-- <h6 class="version-number mb-3">Now v1.6</h6> -->
              <div class="">
                <h4
                  class="text-white wow fadeInUp font-italic"
                  data-wow-duration="1000ms"
                  data-wow-delay="200ms"
                >
                  <span>Тээврийн тооцоолуур</span>
                </h4>
                <div class="font-italic">
                  <h2 class="text-white">
                    <span
                      class="font-weight-bolder"
                      style="margin-right: 10px; color: #d52028"
                      >1 кг</span
                    >
                    <span>ердөө -</span>
                    <span
                      class="font-weight-bolder"
                      style="margin-left: 10px; color: #d52028"
                      >{{ massPrice }}₮</span
                    >
                  </h2>
                </div>
              </div>
              <h3
                class="mb-4 wow fadeInUp text-white font-italic"
                data-wow-duration="1000ms"
                data-wow-delay="200ms"
              >
                Овор хэмжээ том буюу 0.04m3 - ээс том барааг m3-ээр бодно. m3-ийг тухайн
                барааны урт, өргөн, өндрийг тооцоолуурт оруулан олно уу <br />
                <span style="color: #d52028">m3</span> ердөө -
                <span style="color: #d52028">{{ sizePrice }}₮</span>
              </h3>
              <img src="@/assets/bg3.png" width="300px" height="300px" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="about-area about-jc section-padding-120 bg-gray">
      <div class="container">
        <div class="hero--content--area text-white">
          <div class="row">
            <div class="col-12">
              <!-- <div class="d-flex justify-content-around mb-4">
                <div
                  class="jbutton"
                  @click="massTab = false"
                  :class="massTab ? 'black' : 'red'"
                >
                  Хэмжээгээр
                </div>
                <div
                  class="jbutton"
                  @click="massTab = true"
                  :class="massTab ? 'red' : 'black'"
                >
                  Жингээр
                </div>
              </div> -->
              <div class="col-12" v-show="massTab">
                <div>
                  <div class="form-group row mb-4">
                    <label for="kilogram" class="col-sm-2 col-form-label">Жин(кг)</label>
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        id="kilogram"
                        placeholder="Жин"
                        v-model="kilogram"
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-4">
                    <label for="kilogramPrice" class="col-sm-2 col-form-label"
                      >Үнэ(₮)</label
                    >
                    <div class="col-sm-10">
                      <input
                        type="number"
                        class="form-control"
                        id="kilogramPrice"
                        placeholder="Үнэ"
                        v-model="totalKilogramPrice"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="!massTab">
                <div class="form-group row mb-4">
                  <label for="cube-length" class="col-sm-2 col-form-label">Урт(см)</label>
                  <div class="col-sm-10">
                    <input
                      type="number"
                      class="form-control"
                      id="cube-length"
                      placeholder="Урт"
                      v-model="length"
                    />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="cube-width" class="col-sm-2 col-form-label"
                    >Өргөн(см)</label
                  >
                  <div class="col-sm-10">
                    <input
                      type="number"
                      class="form-control"
                      id="cube-width"
                      placeholder="Өргөн"
                      v-model="width"
                    />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="cube-height" class="col-sm-2 col-form-label"
                    >Өндөр(см)</label
                  >
                  <div class="col-sm-10">
                    <input
                      type="number"
                      class="form-control"
                      id="cube-height"
                      placeholder="Өндөр"
                      v-model="height"
                    />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="kilogram" class="col-sm-2 col-form-label">Жин(кг)</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      :class="mass ? '' : 'text-red text-bold'"
                      id="kilogram"
                      placeholder="Жин"
                      v-model="kilogram"
                    />
                  </div>
                </div>

                <div class="form-group row mb-4">
                  <label for="cube-size" class="col-sm-2 col-form-label"
                    >Метр куб(см)</label
                  >
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      id="cube-size"
                      placeholder="Метр куб"
                      v-model="totalSize"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row mb-4">
                  <label for="cubePrice" class="col-sm-2 col-form-label">Үнэ(₮)</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      :class="mass ? '' : 'text-red text-bold'"
                      id="cubePrice"
                      placeholder="Үнэ"
                      v-model="totalPrice"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CargoService from "../services/cargo.service";
export default {
  name: "Calculation",
  data() {
    return {
      length: null,
      width: null,
      height: null,
      kilogram: null,
      mass: false,
      massTab: false,
      sizePrice: null,
      massPrice: null,
    };
  },
  created() {
    CargoService.getPrices()
      .then((res) => {
        this.sizePrice = res.data.body.find((item) => item.type == 1).price;
        this.massPrice = res.data.body.find((item) => item.type == 2).price;
      })
      .catch((err) => {
        console.log(err.response.message);
      });
  },
  watch: {
    totalSize(val) {
      if ((val === null) | (val < 0.04)) {
        this.mass = false;
      } else {
        this.mass = true;
      }
    },
  },
  computed: {
    totalSize() {
      if (this.length && this.width && this.height)
        return (this.length * this.width * this.height) / 1000000;
      else return null;
    },
    totalCubePrice() {
      if (this.totalSize === null || this.totalSize < 0.04) {
        return "Жингээр тооцоолно уу";
      } else {
        return this.totalSize * this.sizePrice;
      }
    },
    totalKilogramPrice() {
      return this.kilogram ? Math.ceil(this.kilogram) * this.massPrice : null;
    },
    totalPrice() {
      if (this.totalSize === null || this.totalSize < 0.04) {
        return Math.ceil(this.kilogram) * this.massPrice;
      } else {
        return this.totalSize * this.sizePrice;
      }
    },
  },
};
</script>

<template>
  <div>
    <section class="welcome-area-black demo-hero" id="home">
      <!-- Background Shape-->
      <div class="background-shape">
        <div
          class="circle1 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle2 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle3 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle4 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
      </div>
      <!-- Background Animation-->
      <div class="background-animation">
        <div class="star-ani"></div>
        <div class="cloud-ani"></div>
        <div class="circle-ani"></div>
        <div class="box-ani"></div>
      </div>
      <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
          <!-- Welcome Content-->
          <div class="col-12 col-md-8">
            <div class="welcome-content text-center">
              <!-- <h6 class="version-number mb-3">Now v1.6</h6> -->
              <div class="">
                <h4
                  class="text-white wow fadeInUp font-italic"
                  data-wow-duration="1000ms"
                  data-wow-delay="200ms"
                >
                  <span>Таны хайсан хуудас олдсонгүй</span>
                </h4>
                <div class="font-italic">
                  <h2 class="text-white">
                    <span
                      class="font-weight-bolder"
                      style="margin-right: 10px; color: #d52028"
                      >404</span
                    >
                  </h2>
                </div>
              </div>
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <div>
    <section class="welcome-area-black demo-hero" id="home">
      <!-- Background Shape-->
      <div class="background-shape">
        <div
          class="circle1 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle2 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle3 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle4 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
      </div>
      <!-- Background Animation-->
      <div class="background-animation">
        <div class="star-ani"></div>
        <div class="cloud-ani"></div>
        <div class="circle-ani"></div>
        <div class="box-ani"></div>
      </div>
      <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
          <!-- Welcome Content-->
          <div class="col-12 col-md-8">
            <div class="welcome-content">
              <!-- <h6 class="version-number mb-3">Now v1.6</h6> -->
              <form @submit.prevent="handleRegister">
                <div class="row mb-2">
                  <div class="col">
                    <div class="form-group">
                      <label class="label-white">Нэр</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Нэр"
                        required
                        v-model="user.firstname"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="label-white">Овог</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Овог"
                        required
                        v-model="user.lastname"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group mb-2">
                  <label class="label-white">Утас</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Утас"
                    v-model="user.phone"
                    required
                  />
                </div>
                <div class="form-group mb-2">
                  <label class="label-white">Утас давтах</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Утас давтах"
                    v-model="confirmPhone"
                    required
                  />
                </div>
                <div class="form-group mb-2">
                  <label class="label-white">Нууц үг</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Нууц үг"
                    v-model="user.password"
                    required
                  />
                </div>
                <div class="form-group mb-2">
                  <label class="label-white">Нууц үг давтах</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Нууц үг давтах"
                    v-model="confirmPassword"
                    required
                  />
                </div>
                <div class="mt-4 text-center">
                  <button type="submit" class="btn btn-red">Бүртгүүлэх</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import User from "../models/user";
export default {
  name: "Profile",
  data() {
    return {
      user: new User(),
      successful: false,
      confirmPhone: null,
      confirmPassword: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister() {
      if (
        this.user.lastname &&
        this.user.firstname &&
        this.user.phone &&
        this.user.password &&
        this.user.phone === this.confirmPhone &&
        this.user.password === this.confirmPassword
      ) {
        this.$store
          .dispatch("auth/register", this.user)
          .then((response) => {
            if (response) this.$router.push("/profile");
          })
          .catch((error) => console.log(error));
      } else {
        this.$toasted.show("Мэдээллээ шалгана уу", {
          type: "error",
          duration: 4000,
          keepOnHover: true,
          icon: "exclamation-circle",
          position: "bottom-right",
        });
      }
    },
  },
};
</script>

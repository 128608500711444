<template>
  <div>
    <section class="welcome-area-black demo-hero" id="home">
      <!-- Background Shape-->
      <div class="background-shape">
        <div
          class="circle1 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle2 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle3 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
        <div
          class="circle4 wow fadeInRightBig"
          data-wow-duration="4000ms"
        ></div>
      </div>
      <!-- Background Animation-->
      <div class="background-animation">
        <div class="star-ani"></div>
        <div class="cloud-ani"></div>
        <div class="circle-ani"></div>
        <div class="box-ani"></div>
      </div>
      <form-track></form-track>
    </section>
    <section class="about-area about-jc section-padding-120 bg-gray">
      <div class="container">
        <div class="hero--content--area">
          <div class="row justify-content-center g-4">
            <div class="single-blog-details-area" v-if="id == 1">
              <h5 class="mb-4 text-white">
                1. Таобао апп татсаны дараа асуух бүх хүсэлтүүдийг зөвшөөрнө.
                Camera, gallery, location гэх мэт. 立即登录” (Нэвтрэх) дарж
                бүртгүүлэх хэсэг рүү орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/1. Нэвтрэх.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                2. “立即注册” (Бүртгүүлэх) даран таобаод хаяг нээнэ.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/2. Бүргүүлэх.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                3. Утасны дугаар оруулахаасаа өмнө “+86” дээр даран улсын кодоо
                өөрчилнө.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/3. Улсын код.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                4. Улсын жагсаалт дотроос “蒙古 +976” (Монгол)-ыг хайж олоод
                сонгоно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/5. Монгол улс.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                5. Утасны дугаараа оруулаад “同意协议并注册” (Нөгцөлийг
                зөвшөөрөх, бүртгүүлэх) дарснаас таны утсан (баталгаажуулах код)
                мессежээр ирэх болно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/4. Утасны дугаар.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                6. Мессэжээр ирэх 6 оронтой (баталгаажуулах код)-ыг оруулсанаар
                таны таобао аккаунт амжилттай нээгдэнэ.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/6. Мессеж код.png"
                alt=""
              />
            </div>
            <div class="single-blog-details-area" v-if="id == 2">
              <h5 class="mb-4 text-white">
                Та бараа захиалахдаа манай эрээний агуулахын хаягыг холбож өгсөн
                байх шаардлагатай бөгөөд доорх зааврийн дагуу холбоно уу.
              </h5>
              <h6 class="mb-4 text-white">
                Анхааруулга: Хаяг холбохдоо буруу бөглөсөн байвал удаж ирэх, огт
                ирэхгүй байх эрсдэлтэй тул анхааралтай бөглөнө үү.
              </h6>
              <h5 class="mb-4 text-white">
                1. Таобао нүүр хуудаснаас 我的淘宝 (Миний таобао) - руу орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/1. Миний таобао.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                2. Зүүн дээд буланд байрлах арааны дүрсэн дээр даран тохиргоо
                хэсэг рүү орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/2. Тохиргоо.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                3. 我的收货地址(Хүргэлтийн хаяг) дээр даран хаягын мэдээлэл рүү
                орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/3. Хүргэх хаяг.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                4. Хүлээн авагчийн мэдээлэл оруулах
              </h5>
              <h6 class="mb-4 text-white">
                * Захиалагчийн мэдээлэл - Jcargo гэж бичээд араас нь таны монгол
                утасны дугаар
              </h6>
              <h6 class="mb-4 text-white">
                * Агуулахын дугаар - 15547933638 гэсэн дугаарыг оруулж өгнө
              </h6>
              <h6 class="mb-4 text-white">
                * Агуулахын байршил - Алхам 5, алхам 6, алхам 7, алхам 8-ийн
                дагуу оруулна
              </h6>
              <h6 class="mb-4 text-white">
                * Агуулахын хаяг - 前进路环宇商贸城14号楼112号 гэсэн хаягыг
                оруулж өгнө
              </h6>
              <h6 class="mb-4 text-white">
                * Гэр, оффис, байгууллага эсэх - 公司(байгууллага) - ийг сонгоно
              </h6>
              <img
                class="mb-5 w-100"
                src="@/assets/help/4. Хаяг оруулах.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                5. Агуулахын байршилыг сонгосны дараа доорх цонх гарж ирэх
                бөгөөд 内蒙古自治区(Өвөр монгол) - ыг сонгоно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/5. Өвөр монгол.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                6. Дараагийн цонхоос 锡林郭勒盟(Шилийн гол аймаг) - ыг сонгоно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/6. Шилийн гол.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                7. Дараагийн цонхоос 二连浩特市(Эрээн хот) - ыг сонгоно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/7. Эрээн хот.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                8. Дараагийн цонхоос 二连浩特市社区建设管理局(Эрээн хотын
                нийтийн бүс) - ыг сонгосноор агуулахыг байршилыг сонгож дуусна.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/8. Эрээн хот2.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                9. Агуулахын мэдээлэл оруулж дууссаны дараа ингэж харагдах
                бөгөөд дуусгах дарснаас хаяг амжилттай хадгалагдана.
              </h5>
              <h6 class="mb-4 text-white">
                Зөвлөмж: Та хаягаа тохируулж дууссаны дараа зургийг манай
                ажилтан луу явуулж шалгуулахыг зөвлөж байна.
              </h6>
              <img
                class="mb-5 w-100"
                src="@/assets/help/9. Дуусгах.png"
                alt=""
              />
            </div>
            <div class="single-blog-details-area" v-if="id == 3">
              <h5 class="mb-4 text-white">
                1. Хүссэн бараагаа хайж олохын тулд түлхүүр үгээр болон зургаар
                хайлт хийх боломжтой.
              </h5>
              <h6 class="mb-4 text-white">
                (Жич: Хайх бараагаа ханзаар хайвал илүү олон илэрц гарч ирэх
                байгаа)
              </h6>
              <img
                class="mb-5 w-100"
                src="@/assets/help/1. Хайлтын хэсэг.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                2. Шүүлтүүрүүд ашиглан хайлтаа илүү өөрийн хүссэнээр удирдах
                боломжтой. Жишээ нь: Үнэ, брэнд, төрөл гэх мэт.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/2. Шүүлтүүрүүд.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                3. Зургаар хайхдаа камерийн дүрсэн дээр дарна.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/3. Камерны дүрс.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                4. Камераар хайх барааныхаа зургийг авна.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/4. Зураг авна.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                5. Таны авсан зургийг таобао автоматаар засварлана. Тэхдээ илүү
                нарийвчлалтай хайхын тулд гар аргаар засварлавал тохиромжтой.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/5. Зураг засварлах.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                6. Таобаогийн зурган хайлтын систем нь таны зургийн дагуу хайлт
                хийн танд үр дүнд харуулна.
              </h5>
              <img class="mb-5 w-100" src="@/assets/help/6. Илэрц.png" alt="" />
            </div>
            <div class="single-blog-details-area" v-if="id == 4">
              <h5 class="mb-4 text-white">Бараа сонгох зөвөлгөө <br /></h5>
              <h5 class="mb-4 text-white">1. Дэлгүүрийн зэрэглэл</h5>
              <h6 class="mb-4 text-white">
                ❤️ Зэрэглэлийн дэлгүүрүүд нь шинээр үйл ажиллагаа явуулж байгаа
                дэлгүүрүүд юм. Энэ зэрэглэлийн дэлгүүрүүд үйл ажиллагаа явуулаад
                удаагүй байдаг тул бараа захиалахдаа маш хянамгай хандаж байхыг
                зөвлөж байна. (Бараагаа удааж явуулах, гаргахгүй байх
                магадлалтай)
              </h6>
              <h6 class="mb-4 text-white">
                💎 Зэрэглэлийн дэлгүүрүүд нь тодорхой хэмжээнд үйл ажиллагаа нь
                жигдэрч эхэлж байгаа дэлгүүрүүд багтдаг. Энэ зэрэглэлийн
                дэлгүүрээс бараа захиалахдаа дэлгүүрийн үнэлгээг давхар нягтлан
                үзэж захиалж байхыг зөвлөж байна.
              </h6>
              <h6 class="mb-4 text-white">
                👑 Цэнхэр болон шар титэмтэй зэрэглэлийн дэлгүүрүүд үйл
                ажиллагаа явуулаад удсан сайн дэлгүүрүүд ихэвчлэн байдаг бөгөөд
                мөн бараа захиалахдаа дэлгүүрийн үнэлгээг давхар нягтлан үзэж
                захиалж байхыг зөвлөж байна.
              </h6>
              <img
                class="mb-5 w-100"
                src="@/assets/help/1. Дэлгүүрийн зэрэглэл.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                2. Дэлгүүрийн үнэлгээ 宝贝描述(Тодорхойлолт) - Тухайн барааны
                тайлбар зураг тодорхойлолт нь хир таарч байгааг илтэгдэг үнэлгээ
                юм. 卖家服务(Дэлгүүрийн үйлчилгээ) - Тухайн барааг зарж буй
                дэлгүүрийн үйлчилгээг илтэгдэг үнэлгээ юм. Дэлгүүрийн
                үйлчилгээнд чатаараа хурдан хариу өгж байгаа эсэх, бараанд
                асуудал гарвал хир хурдан шуурхай шидэж өгч байгаа гэх мэт зүйлч
                орно. 物流服务(Хүргэлт) - Тухайн дэлгүүрийн хүргэлтийн чанарыг
                илтэгдэг үнэлгээ юм. Үүнд дэлгүүр нь заасан цагтаа бараагаа
                хүргэлтээр гаргадаг эсэх, сав баглаа боодол, хүргэлтийн хурд гэх
                мэт зүйлс орно.
              </h5>
              <h6 class="mb-4 text-white">
                Жич: <br />
                1. Зарим тохиолдол дэлгүүрийн үнэлгээ муу ч хямд чанартай бараа
                ирэх тохиодол байдаг байгаа. <br />
                2. 4,6-гаас 4,9 үнэлгээтэй дэлгүүрээс бараа захиалбал чанартай
                бараа авах магадлал өндөр байдаг тус манайх үнэлгээ сайтай
                дэлгүүрээс бараа захиалахыг уриалж байна. Анхааруулга: Нэгэнт
                УБ-д ирсэн барааг буцаахад цаг хугацаа болон буцаах тээврийн
                зардал их ордог учир дэлгүүрийн үнэлгээг бодолцон үзсэний эцэст
                захиалга хийнэ үү.
              </h6>
              <img
                class="mb-5 w-100"
                src="@/assets/help/2. Дэлгүүрийн үнэлгээ.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                3. Үйлчлүүлэгчдийн сэтгэгдэл Энэ хэсэгт тухайн барааг өмнө
                захиалж авсан хүмүүсийн сэтгэгдэл байх бөгөөд олон сэтгэгдэлтэй
                байвал олон хүн өмнө нь авч байсан гэж ойлгож болно. Зарим
                сэтгэгдлүүд тухайн барааны зурагтай байдаг учир бараа бодит
                зургийг эндээс харах боломжтой Жич: Орчуулагч(translate) ашиглан
                сэтгэгдэлүүдийг орчуулан харах боломжтой
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/3. Сэтгэгдэл.png"
                alt=""
              />
            </div>
            <div class="single-blog-details-area" v-if="id == 5">
              <h5 class="mb-4 text-white">
                1. Сонгосон барааруугаа ороод улбар шар дээр бичсэн “加入购物车”
                (Сагслах) дээр дарна.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/1. Сагслах.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                2. Тухайн барааны тоо ширхэг, өнгө размер, загварыг сонгоно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/2. Өнгө сонгох.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                3. Сонгож дууссаны дараа “确定” (Баталгаажуулах) дарснаар тухайн
                бараа сагсанд амжилттай орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/3. Баталгаажуулах.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                4. Нийт захиалах бараагаа бүгдийг нь сагсалж дууссаны дараа нүүр
                хуудсанд байрлах “购物车” (Сагс) дээр даран сагсруугаа орно
              </h5>
              <img class="mb-5 w-100" src="@/assets/help/4. Сагс.png" alt="" />
              <h5 class="mb-4 text-white">
                5. Сагсанд орход таны өмнө нь сагсалсан бараанууд харагдах
                бөгөөд захиалах бараагаа нэг нэгээр эсвэл “全选” (Бүгдийг
                сонгох) даран сонгоод “结算” (Захиалга илгээх) даран дараагийн
                хуудас руу орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/5. Захиалга илгээх.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                6. Энэ хуудсан үнийн нийт задаргаа гарч ирэх бөгөөд үүнд тус
                бүрийн барааны үнэ, тус бүрийн хятад доторх тээврийн үнэ, нийт
                үнэ гэх мэт зүйлс харагдана. Төлбөр төлөх даран цааш
                үргэлжлүүлнэ.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/6. Төлбөр төлөх.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                7. Төлбөрийн хуудас гарч ирэх бөгөөд картаар төлбөр төлөхийг
                сонгоод Картын мэдээллийн хэсгийг үнэн зөв бөглөн үргэлжлүүлэх
                дарахад таны захиалганы төлбөр төлөгдөн баталгаажна. Жич: Зөвхөн
                олон улсын visa/mastercard ашиглах боломжтой бөгөөд unionpay
                карт ашиглах боломжгүй
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/7. Карт холбох.png"
                alt=""
              />
            </div>
            <div class="single-blog-details-area" v-if="id == 6">
              <h5 class="mb-4 text-white">
                Буцаах бараанд дэлгүүр нь бараагаа гаргаагүй, захиалсанаар өөр
                бараа ирсэн, захиалсан тооноос дутуу ирсэн гэх мэт зүйлс
                хамаарах бөгөөд зөв ирсэн барааг covid-19 холбоотой буцаах
                боломжгүй байгааг анхаарна уу
              </h5>
              <h5 class="mb-4 text-white">
                1. Таобао нүүр хуудаснаас 我的淘宝 (Миний таобао) - руу орно.
              </h5>
              <img class="mb-5 w-100" src="@/assets/help/1. Нүүр.png" alt="" />
              <h5 class="mb-4 text-white">
                2. Миний таобао дотроос 我的订单 (Миний захиалгууд)-руу орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/2. Бүх захиалга.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                3. Захиалгууд дундаасаа буцаах бараагаа хайж олно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/3. Буцаах бараа.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                4. Буцаах бараан дээр нь дарж ороод “退款” (Буцаах) гэсэнг
                дарна.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/4. Буцаах хүсэлт.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                5. Буцаах хүсэлтрүүгээ ороод “退款原因” (Буцаах шалгаан)
                сонгоно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/5. Шалтгаан.png"
                alt=""
              />
              <h5 class="mb-4 text-white">6. Буцаах шалтгаанд:</h5>
              <h6 class="mb-4 text-white">
                拍错/多拍/不想要 - Буруу захиалсан/Илүү захиалсан/Авахаа больсон
              </h6>
              <h6 class="mb-4 text-white">
                协商一致退款 - Дэлгүүр нь буцаахыг зөвшөөрсөн
              </h6>
              <h6 class="mb-4 text-white">缺货 - Бараа нь дууссан</h6>
              <h6 class="mb-4 text-white">
                未按约定时间发货 - Дэлгүүр бараагаа заасан хугацаандаа
                явуулаагүй
              </h6>
              <h6>class="mb-4 text-white"其他 - Бусад</h6>
              <img
                class="mb-5 w-100"
                src="@/assets/help/6. Шалтгаан сонгох.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                7. Хэрвээ нэмэлт тайлбар хэрэгтэй бол 退款说明(Нэмэлт тайлбар)
                дарж оруулж өгнө. <br />
                Түгээмэл тайлбарууд:
              </h5>
              <h6 class="mb-4 text-white">
                尺寸太大 /尺寸太小 - Томдсон/багадсан
              </h6>
              <h6 class="mb-4 text-white">质量不好 - Чанаргүй</h6>
              <h6 class="mb-4 text-white">
                物品有色差- Зурган дээрхээсээ өөр өнгөтэй
              </h6>
              <h6 class="mb-4 text-white">
                收到的宝贝已经损坏了 - Гэмтэлтэй бараа ирсэн
              </h6>
              <h6 class="mb-4 text-white">
                发错物品 - Захиаланаас өөр бараа явуулсан
              </h6>
              <h6 class="mb-4 text-white">
                物品数量错了，我拍了 （5）个，但只收到 （3）个 - Бараа дутуу
                ирсэн, нийт 5ш захиалсанаас, 3ш ирсэн
              </h6>
              <img
                class="mb-5 w-100"
                src="@/assets/help/7. Нэмэлт тайлбар.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                8. Хэрвээ зураг оруулах шаардлагатай бол “上传凭证” (Upload)
                дарж нийт 6 хүртэл зураг оруулах боломжтой. Дээрх зүйлсийг
                бөглөж дууссаны “提交” (Илгээх) дарж буцаах хүсэлтээ илгэээнэ.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/8. Зураг оруулах.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                9. Хүсэлт илгээсний дараа дэлгүүр нь 48 цагийн дотор таны
                хүсэлтэнд хариу өгнө. Та хүлээх хугацаандаа “撤销申请” (Хүсэлт
                цуцлах), “修改申请” (Нэмэлт өөрчлөлт оруулах) боломжтой.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/9. Цуцлах өөрчлөх.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                10. Таны буцаалтын хүсэлтийн хариу сонордуулга дээр ирнэ.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/10. Сонордуулга.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                11. Содордуулгаруу ороод “交易物流” (Гүйлгээний сонордуулга)-руу
                орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/11. Гүйлгээний мэдээлэл.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                12. Гүйлгээний сонордуулга руу ороход буцаалтын сонордуулга
                харагдана. Дээр нь дарж орно.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/12. Буцаалтын сонордуулга.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                13. Улбар шар дээр “退款成功” (Буцаалтын хүсэлт амжилттай) гэсэн
                анхааруулга ирсэн байвал. Таны буцаалт удахгүй орохыг илтгэнэ.
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/13. Буцаалт баталгаажсан.png"
                alt=""
              />
              <h5 class="mb-4 text-white">
                “卖家退款” (Дэлгүүрийн буцаалт хийсэн он сар өдөр)
              </h5>
              <img
                class="mb-5 w-100"
                src="@/assets/help/14. Дэлгүүр огноо.png"
                alt=""
              />
              <img
                class="mb-5 w-100"
                src="@/assets/help/15. Банк огноо.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FormTrack from '../components/FormTrack.vue';
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
export default {
  components: { FormTrack },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  mounted() {
    this.test();
  },
  methods: {
    test() {
      console.log(this.id);
    },
  },
};
</script>

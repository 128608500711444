<template>
  <div>
    <section class="about-area about-jc section-padding-120 bg-gray">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-red font-italic">
            Тусламж
          </h1>
        </div>
        <div class="hero--content--area">
          <div class="row justify-content-center g-4">
            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t1.mp4')"
                src="@/assets/help2/1.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t2.mp4')"
                src="@/assets/help2/2.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t3.mp4')"
                src="@/assets/help2/3.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('tr4.mp4')"
                src="@/assets/help2/4.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('tr5.mp4')"
                src="@/assets/help2/5.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t6.mp4')"
                src="@/assets/help2/6.png"
                alt=""
              />
            </div>
            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t7.mp4')"
                src="@/assets/help2/7.png"
                alt=""
              />
            </div>
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t8.mp4')"
                src="@/assets/help2/8.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal :width="320" :height="240" name="video">
      <video autoplay width="320" height="240" controls>
        <source :src="'/media/' + video" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
export default {
  name: "Help",
  data() {
    return {
      video: "",
    };
  },
  mounted() {},
  methods: {
    play(value) {
      this.video = value;
      this.$modal.show("video");
    },
  },
};
</script>

<template>
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
      <!-- Welcome Content-->
      <div class="col-12 col-md-8">
        <div class="welcome-content text-center">
          <!-- <h6 class="version-number mb-3">Now v1.6</h6> -->
          <div class="a">
            <img src="/img/nuurp.png" alt="" />
            <div class="b">
              <h4
                class="text-white wow fadeInUp font-italic"
                data-wow-duration="1000ms"
                data-wow-delay="200ms"
              >
                <span>Эрээн-Улаанбаатар карго тээвэр</span>
              </h4>
              <div class="font-italic">
                <h2 class="text-white">
                  <span
                    class="font-weight-bolder"
                    style="margin-right: 10px; color: #d52028"
                    >1 кг</span
                  >
                  <span>ердөө -</span>
                  <span
                    class="font-weight-bolder"
                    style="margin-left: 10px; color: #d52028"
                    >{{ massPrice }}₮</span
                  >
                </h2>
              </div>
            </div>
          </div>
          <!-- <img src="@/assets/transport.png" alt="" /> -->
          <div class="col-12 col-md-12">
            <h3
              class="mb-4 wow fadeInUp text-white font-italic track"
              data-wow-duration="1000ms"
              data-wow-delay="200ms"
            >
              Илгээмж бараа бүртгэл (Трак №)
            </h3>
            <form @submit.prevent="approval">
              <div class="mb-4">
                <input
                  style="
                    border: 0;
                    border-bottom: 2px solid transparent;
                    font-size: 14px;
                    border-radius: 10px !important;
                  "
                  class="form-control rounded-0 wow fadeInUp"
                  type="text"
                  placeholder="Захиалсан барааны трак дугаарыг энд бичнэ үү"
                  data-wow-duration="1000ms"
                  data-wow-delay="200ms"
                  v-model="track.track_code"
                />
              </div>

              <div class="mb-4">
                <select
                  style="
                    border: 0;
                    border-bottom: 2px solid transparent;
                    font-size: 14px;
                    border-radius: 10px !important;
                  "
                  class="form-control rounded-0 wow fadeInUp"
                  placeholder="Захиалсан барааны трак дугаарыг энд бичнэ үү"
                  data-wow-duration="1000ms"
                  data-wow-delay="200ms"
                  v-model="track.branch_id"
                >
                  <option v-for="branch in branches" :value="branch.id" :key="branch.id">
                    {{ branch.name }}
                  </option>
                </select>
              </div>
              <div class="mb-4">
                <label class="checkbox-container text-white wow fadeInUp font-italic"
                  >Хүргэлтээр авах
                  <input type="checkbox" v-model="track.pick_up" />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="mb-4">
                <button
                  class="btn btn-red mt-5 wow fadeInUp"
                  data-wow-duration="1000ms"
                  data-wow-delay="400ms"
                  type="submit"
                  :disabled="loading"
                >
                  Хадгалах
                </button>
              </div>
            </form>
          </div>

          <modal
            v-if="contract.id"
            width="80%"
            height="80%"
            :adaptive="true"
            styles="border: 1px solid #fff; border-radius: 16px; padding: 16px; background: #fff;"
            name="contract"
          >
            <button
              class="btn btn-success mt-3 wow fadeInUp mb-3"
              data-wow-duration="1000ms"
              data-wow-delay="400ms"
              type="button"
              @click="handleTrack"
            >
              Зөвшөөрөх
            </button>
            <button
              class="btn btn-danger mt-3 wow fadeInUp ml-3 mb-3"
              data-wow-duration="1000ms"
              data-wow-delay="400ms"
              type="button"
              @click="disagree"
            >
              Татгалзах
            </button>

            <iframe width="100%" height="100%" :src="contract.filename"></iframe>
            <div @click="$modal.hide('contract')" class="close-button">❌</div>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import CargoService from "../services/cargo.service";

export default {
  name: "FormTrack",
  data() {
    return {
      track: {
        track_code: null,
        branch_id: null,
        pick_up: false,
      },
      branches: [],
      loading: false,
      sizePrice: null,
      massPrice: null,
      approved: false,
      contract: {},
    };
  },

  created() {
    CargoService.getPrices()
      .then((res) => {
        this.sizePrice = res.data.body.find((item) => item.type == 1).price;
        this.massPrice = res.data.body.find((item) => item.type == 2).price;
      })
      .catch((err) => {
        console.log(err.response.message);
      });

    CargoService.getBranches()
      .then((res) => {
        this.branches.push({
          id: null,
          name: "Бараа хүлээн авах салбар сонгоно уу",
          address: null,
          phone: null,
        });
        this.branches = this.branches.concat(res.data.body);
      })
      .catch((err) => {
        console.log(err.response.message);
      });

    if (this.loggedIn) {
      UserService.getLatestContacts()
        .then((res) => {
          this.contract = res.data.body;
        })
        .catch((err) => {
          console.log(err.response.message);
        });

      UserService.getApproval()
        .then((res) => {
          this.approved = res.data.body;
        })
        .catch((err) => {
          console.log(err.response.message);
        });
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    approval() {
      if (this.loggedIn && this.approved) this.handleTrack();
      else if (this.loggedIn && !this.approved) this.$modal.show("contract");
      else {
        this.$toasted.show("Нэвтэрч орно уу", {
          type: "error",
          duration: 4000,
          keepOnHover: true,
          icon: "exclamation-circle",
          position: "bottom-right",
        });
        this.loading = false;
      }
    },
    disagree() {
      this.$toasted.show(
        "Уучлаарай үйлчилгээний нөхцөл зөвшөөрөөгүй бол бүртгэл хийх боломжгүй",
        {
          type: "error",
          duration: 3000,
          keepOnHover: true,
          icon: "exclamation-circle",
          position: "top-center",
        }
      );
    },
    async handleTrack() {
      this.loading = true;
      if (!this.loggedIn) {
        this.$toasted.show("Нэвтэрч орно уу", {
          type: "error",
          duration: 4000,
          keepOnHover: true,
          icon: "exclamation-circle",
          position: "bottom-right",
        });
        this.loading = false;
      } else {
        UserService.storeContract({ contract_id: this.contract.id })
          .then((result) => {
            this.contract = result.data.body;
            this.approved = true;
            console.log("Contract approved");
          })
          .catch((err) => {
            console.log("err.response", err.response);
          });
        if (this.track.track_code.trim().length < 5) {
          this.$toasted.show("Илгээмжийн дугаараа зөв оруулна уу. ", {
            type: "error",
            duration: 4000,
            keepOnHover: true,
            icon: "exclamation-circle",
            position: "top-center",
          });
          this.loading = false;
          return;
        }

        await UserService.postTrack(this.track)
          .then((res) => {
            let trk = res.data.body;
            this.track.track_code = null;
            this.$toasted.show("Таны илгээмжийн бүртгэлийн дугаар: " + trk.track_number, {
              type: "success",
              duration: 6000,
              keepOnHover: true,
              icon: "exclamation-circle",
              position: "top-center",
            });
            this.loading = false;
            // alert("Таны илгээмжийн бүртгэлийн дугаар: " + trk.track_number);
          })
          .catch((error) => {
            this.$toasted.show(error.response.data.message, {
              type: "error",
              duration: 6000,
              keepOnHover: true,
              icon: "exclamation-circle",
              position: "top-center",
            });

            this.loading = false;
          });
      }
    },
  },
};
</script>

<style></style>

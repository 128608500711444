<template>
  <div>
    <section class="welcome-area-black demo-hero" id="home">
      <!-- Background Shape-->
      <div class="background-shape">
        <div class="circle1 wow fadeInRightBig" data-wow-duration="4000ms"></div>
        <div class="circle2 wow fadeInRightBig" data-wow-duration="4000ms"></div>
        <div class="circle3 wow fadeInRightBig" data-wow-duration="4000ms"></div>
        <div class="circle4 wow fadeInRightBig" data-wow-duration="4000ms"></div>
      </div>
      <!-- Background Animation-->
      <div class="background-animation">
        <div class="star-ani"></div>
        <div class="cloud-ani"></div>
        <div class="circle-ani"></div>
        <div class="box-ani"></div>
      </div>
      <form-track></form-track>
    </section>
    <section class="about-area about-jc section-padding-120 bg-gray">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-red font-italic">Тусламж</h1>
        </div>
        <div class="hero--content--area">
          <div class="row justify-content-center g-4">
            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t1.mp4')"
                src="@/assets/help2/1.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t2.mp4')"
                src="@/assets/help2/2.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t3.mp4')"
                src="@/assets/help2/3.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('tr4.mp4')"
                src="@/assets/help2/4.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('tr5.mp4')"
                src="@/assets/help2/5.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t6.mp4')"
                src="@/assets/help2/6.png"
                alt=""
              />
            </div>
            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t7.mp4')"
                src="@/assets/help2/7.png"
                alt=""
              />
            </div>
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('t8.mp4')"
                src="@/assets/help2/8.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal :width="320" :height="240" name="video">
      <video autoplay width="320" height="240" controls>
        <source :src="'/media/' + video" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </modal>
    <!--    <reviews></reviews>-->
  </div>
</template>

<script>
// import Reviews from "../components/Reviews";
import FormTrack from "../components/FormTrack";
export default {
  name: "Home",
  data() {
    return {
      video: "",
    };
  },
  components: { FormTrack },
  mounted() {},
  methods: {
    play(value) {
      this.video = value;
      this.$modal.show("video");
    },
  },
};
</script>

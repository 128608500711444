import axios from 'axios';
import authHeader from './auth-header';
import api from '../models/api';

class UserService {
    getUserProfile() {
        return axios.get(api.url + 'profile', { headers: authHeader() });
    }

    updateProfile(user) {
        return axios.put(api.url + 'update', user, { headers: authHeader() })
    }

    changePassword(passArr) {
        return axios.post(api.url + 'change', passArr, { headers: authHeader() })
    }

    getTracks() {
        return axios.get(api.url + 'tracks', { headers: authHeader() });
    }

    postTrack(data) {
        return axios.post(api.url + 'track/store', data, { headers: authHeader() });
    }

    getNews() {
        return axios.get(api.url + 'news', { headers: authHeader() });
    }

    postReview(feedback) {
        return axios.post(api.url + 'feedback', { feedback: feedback }, { headers: authHeader() });
    }

    getApproval() {
        return axios.get(api.url + 'contract/approval', { headers: authHeader() });
    }

    getLatestContacts() {
        return axios.get(api.url + 'contract/latest', { headers: authHeader() });
    }

    storeContract(contract) {
        return axios.post(api.url + 'contract/store', contract, { headers: authHeader() });
    }

}

export default new UserService();